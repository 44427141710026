@import "~@lexdania/components/build/lib/styles/colors/_lovtidende-colors.scss";
@media (max-width: 450px) {
  .px-xs-0 {
    padding-left: 0;
    padding-right: 0;
  }
}

body ::selection {
  color: $primary-color-font;
  background-color: $primary-color;
}

body ::-moz-selection {
  color: $primary-color-font;
  background-color: $primary-color;
}

.main-content-body {
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 100%;
}

.container.content-area {
  .alert {
    margin-top: 10px;
    border: none;

    button {
      border: none;
      background-color: transparent;
      margin-right: 10px;
      cursor: pointer;
    }

    h5 {
      margin: 10px 0;
    }
  }

  .live-search.form-group {
    margin-bottom: none;
  }
}
